<template>
  <div class="app">
    <Navigation />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import './styles/global.scss'
import Navigation from "./components/Navigation.vue"
import Footer from "./components/Footer.vue"

export default {
  name: "App",
  methods: {
    scrollToTop: function () {
      console.log('ei')
			document.getElementById('app').scrollIntoView();
		}
  },
  components: {
    Navigation,
    Footer,
  },
};
</script>