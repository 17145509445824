<template>
<div class="container">
    <div class="gallery-container w-1 h-2">
        <div class="gallery-item">
            <div class="image">
                <img src="@/assets/gallery-chale/01.jpg" alt="nature">
            </div>
            <!-- <div class="text">nature</div> -->
        </div>
    </div>
    <div class="gallery-container w-1 h-1">
        <div class="gallery-item">
            <div class="image">
                <img src="@/assets/gallery-chale/02.jpg" alt="nature">
            </div>
            <!-- <div class="text">nature</div> -->
        </div>
    </div>
    <div class="gallery-container w-3 h-2">
        <div class="gallery-item">
            <div class="image">
                <img src="@/assets/gallery-chale/03.jpg" alt="nature">
            </div>
            <!-- <div class="text">nature</div> -->
        </div>
    </div>
    <div class="gallery-container w-1 h-2">
        <div class="gallery-item">
            <div class="image">
                <img src="@/assets/gallery-chale/04.jpg" alt="nature">
            </div>
            <!-- <div class="text">nature</div> -->
        </div>
    </div>
    <div class="gallery-container w-1 h-1">
        <div class="gallery-item">
            <div class="image">
                <img src="@/assets/gallery-chale/05.jpg" alt="nature">
            </div>
            <!-- <div class="text">nature</div> -->
        </div>
    </div>
    <div class="modal">
        <img class="full-img" src="" />
    </div>
</div>
</template>

<script>
export default {
 name: 'GalleryChale',
 mounted() {
    const modal = document.querySelector(".modal");
    const previews = document.querySelectorAll(".gallery-item img");
    const original = document.querySelector(".full-img");

    previews.forEach(preview => {
        preview.addEventListener('click', () => {
            console.log('modal', modal)
            modal.classList.add('open')
            original.classList.add('open')
            // dynamic change image
            const originalSrc = preview.getAttribute('src')
            // original.src = `../assets/gallery-benjaficus/${originalSrc}`
            original.src = originalSrc
        })
    })

    modal.addEventListener('click', (e) => {
        if (e.target.classList.contains('modal')) {
            modal.classList.remove('open')
            // modal.style.visibility = "hidden";
        }
    })
 }
}
</script>

<style lang="scss" scoped>
.container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-auto-rows: 200px 300px;
    grid-gap: 20px;
    grid-auto-flow: dense;

    .modal {
        background: rgba($color: #000000, $alpha: 0.6);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        pointer-events: none;
        transition: 0.25s ease-out;
        display: none;

        .full-img {
            position: absolute;
            height: 130%;
            top: 50%;
            left: 50%;
            border-radius: 20px;
            transform: translate(-50%, -50%) scale(0.5);
            transition: all 0.25s ease-out;

            .open {
                transform: translate(-50%, -50%) scale(1);
            }
        }

    }

    .open {
            opacity: 1;
            pointer-events: all;
            display: block;
        }

    .gallery-item {
        width: 100%;
        height: 100%;
        position: relative;

        .image {
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-radius: 8px;
        }

        .image img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 50% 50%;
            cursor: pointer;
            transition: .5s ease-in-out;    
        }

        &:hover {
            /* .image img {
                transform: scale(1.5);
            } */
        }

        .text {
            opacity: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            color: #fff;
            font-size: 25px;
            pointer-events: none;
            z-index: 4;
            transition: .3s ease-in-out;
            -webkit-backdrop-filter: blur(5px) saturate(1.8);
            backdrop-filter: blur(5px) saturate(1.8);    
        }
        
        &:hover {
            .text {
                opacity: 1;
                animation: move-down .3s linear;
                padding: 1em;
                width: 100%;
                /* outline: 2px solid red; */
            }
        }
    }
}

@media (max-width: 550px) {
    .container {
        grid-template-columns: repeat(1, 1fr);

        .modal {
            .full-img {
                height: 55%;
            }
        }

        .w-1,.w-2,.w-3,.w-4,.w-5 {
            grid-column: span 1;
        }
    }
}

@keyframes move-down {
    0% {
        transform: scale(1.1)
    }
    50% {
        transform: scale(.8)
    }
    100% {
        transform: scale(1)
    }
}

/* span */
.w-1 {
    grid-column: span 1;
}
.w-2 {
    grid-column: span 2;
}
.w-3 {
    grid-column: span 3;
}
.w-4 {
    grid-column: span 1;
}
.w-5 {
    grid-column: span 5;
}
.w-6 {
    grid-column: span 6;
}
.h-1 {
    grid-row: span 1;
}
.h-2 {
    grid-row: span 2;
}
.h-3 {
    grid-row: span 3;
}
.h-4 {
    grid-row: span 4;
}
.h-5 {
    grid-row: span 5;
}
.h-6 {
    grid-row: span 6;
}
/* end span */
</style>