<template>
	<header :class="{ 'scrolled-nav': scrollNav }">
		<nav class="container">
			<div class="branding">
				<router-link class="logo" :to="{name: 'Home'}">
					<img src="@/assets/logo-prisma02.png" alt="" >
				</router-link>
			</div>
			<transition name="fade">
				<div @click="toggleMobileNav" id="menu-overlay" v-show="menuActive"></div>
			</transition>
				<ul v-show="!mobile" class="navigation">
					<li><router-link class="link" :to="{name: 'Home'}">Home</router-link></li>
					<li><router-link class="link" :to="{name: 'Chale'}">Chalé Prisma da Colina</router-link></li>
					<li><router-link class="link" :to="{name: 'BenjaFicus'}">BenjaFicus</router-link></li>
				</ul>
			<div class="icon">
				<i @click="toggleMobileNav" v-show="mobile" class="far fa-bars" :class="{ 'icon-active': mobileNav }"></i>
			</div>
			<transition name="mobile-nav">
				<ul v-show="mobileNav" class="dropdown-nav">
					<li @click="toggleMobileNav"><router-link class="link" :to="{name: 'Home'}">Home</router-link></li>
					<li @click="toggleMobileNav"><router-link class="link" :to="{name: 'Chale'}">Chalé Prisma da Colina</router-link></li>
					<li @click="toggleMobileNav"><router-link class="link" :to="{name: 'BenjaFicus'}">BenjaFicus</router-link></li>
				</ul>
			</transition>
		</nav>
	</header>
</template>

<script>
export default {
  name: 'Navigation',
  data() {
	return {
	  scrollNav: null,
	  mobile: null,
	  mobileNav: null,
	  windowWidth: null,
	  menuActive: false
	}
  },
  created() {
	window.addEventListener('resize', this.checkScreen)
	this.checkScreen()
  },
  mounted() {
	window.addEventListener('scroll', this.updateScroll)
  },
  methods: {
	toggleMobileNav() {
	  this.menuActive = !this.menuActive
	  this.mobileNav = !this.mobileNav
	},

	updateScroll() {
	  const scrollPosition = window.scrollY
	  if ( scrollPosition > 50) {
		this.scrollNav = true
		return
	  }
	  this.scrollNav = false
	},
	
	checkScreen() {
	  this.windowWidth = window.innerWidth
	  if (this.windowWidth <= 750) {
		this.mobile = true
		return
	  }
	  this.mobile = false
	  this.mobileNav = false
	  return
	}
  }
}
</script>

<style lang='scss' scoped>

header {
  background-color: rgba(0,0,0,0.6);
  z-index: 99;
  width: 100%;
  position: fixed;
  transition: 0.5s ease all;
  color: #fff;

  nav {
		position: relative;
		display: flex;
		flex-direction: row;
		padding: 12px 0;
		transition: .5s ease all;
		width: 90%;
		margin: 0 auto;
		@media(min-width: 1140px) {
			max-width: 1140px;
	}

	#menu-overlay {
	  position: fixed;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  background-color: #000;
	  opacity: 0.6;
	  @media (min-width:550px) {
		#menu-overlay {
			display: none;
		}
	  }
	}

	ul,	.link {
		font-weight: 500;
		color: #fff;
		list-style: none;
		text-decoration: none;
	}

	li {
		text-transform: uppercase;
		padding-left: 3rem;
		cursor: pointer;
		font-weight: 300;
		font-family: $font-navigation;
	}

	.link {
		font-size: 14px;
		transition: 0.5s ease all;
		padding-bottom: 4px;
		border-bottom: 1px solid transparent;

		&:hover {
		color: #00afea;
		border-color: #00afea;
		font-weight: 500;
		}
	}

	.logo {
		transition: 300ms;

		&:hover {
			/* opacity: 0.8; */
		}
	}

	.branding {
	  display: flex;
	  align-items: center;

	  img {
		width: 50px;
		transition: 0.5s ease all;
	  }
	}

	.navigation {
	  display: flex;
	  align-items: center;
	  flex: 1;
	  justify-content: flex-end;
	}

	.icon {
	  display: flex;
	  align-items: center;
	  position: absolute;
	  top: 0;
	  right: 24px;
	  height: 100%;

	  i {
		cursor: pointer;
		font-size: 24px;
		transition: 0.8s ease all;
	  }
	}

	.icon-active {
	  transform: rotate(180deg)
	}

	.dropdown-nav {
	  display: flex;
	  flex-direction: column;
	  padding-left: 1rem;
	  position: fixed;
	  width: 100%;
	  max-width: 240px;
	  height: 100%;
	  background-color: #fff;
	  /* opacity: 0.8; */
	  top: 0;
	  left: 0;

	  li {
		padding-left: 0.6rem;
		.link {
		  color: #000;
		}
	  }
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.5s ease;
	}

	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
	}

	.mobile-nav-enter-active,
	.mobile-nav-leave-active {
		transition: 1s ease all;
	}

	.mobile-nav-enter-from,
	.mobile-nav-leave-to {
		transform: translateX(-250px);
	}

	.mobile-nav-enter-to {
		transform: translateX(0);
	}
  }
}

.scrolled-nav {
  background-color: #000;
  box-shadow: 0 4px 6px -1px rgb(0, 0, 0, 0.1), 0 2px 4px -1px rgb(0, 0, 0, 0.06);

  nav {
	padding: 8px 0;

	.branding {
	  img {
		widows: 40px;
		box-shadow: 0 4px 6px -1px rgb(0, 0, 0, 0.1), 0 2px 4px -1px rgb(0, 0, 0, 0.06);
	  }
	}
  }
}

@media (max-width:550px) {
	header {
		nav {
			li {
				padding-top: 2rem;
			}
		}
	}
}

@media (max-width:750px) {
	header {
		nav {
			li {
				padding-top: 2rem;
			}
		}
	}
}

</style>