<template>
    <section class="card-container">
        <div class="card">
            <div class="card-top">
                <router-link :to="{name: 'Chale'}"><img src="../assets/gallery-chale/01.jpg" alt="Unsplash Photo"></router-link>
            </div>
            <div class="card-content">
                <h6 class="tag tag-travel">CHALÉ</h6>
                <router-link :to="{name: 'Chale'}"><h3 class="title">Prisma da Colina</h3></router-link>
                <p>Espaço para até 4 pessoas com duas camas de casal.</p>
                <router-link  :to="{name: 'Chale'}"><h3 class="button">Saiba mais...</h3></router-link>
            </div>
        </div>
        <div class="card">
            <div class="card-top">
                <router-link  :to="{name: 'BenjaFicus'}"><img src="../assets/gallery-benjaficus/04.jpg" alt="Unsplash Photo"></router-link >
            </div>
            <div class="card-content">
                <h6 class="tag tag-nature">CASA DA ARVORE</h6>
                <router-link  :to="{name: 'BenjaFicus'}"><h3 class="title">BenjaFicus</h3></router-link>
                <p>Espaço com dois andares, cozinha e banheiro separados.</p>
                <router-link  :to="{name: 'BenjaFicus'}"><h3 class="button">Saiba mais...</h3></router-link>
            </div>
        </div>
        <!-- <div class="card">
            <div class="card-top">
                <a href=""><img src="../assets/gallery/03.jpg" alt="Unsplash Photo"></a>
            </div>
            <div class="card-content">
                <h6 class="tag tag-architecture">ARCHITECTURE</h6>
                <a href=""><h3 class="title">Hign Rise Building</h3></a>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>
        </div> -->
    </section>
</template>

<script>
export default {

}
</script>

<style lang="scss">
/* .principal {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    min-height: 640px;
    background-color: #d1e8ee;
} */

h3 {
    font-family: var(--fonts-style-x);
    font-size: 2.25em;
}

h6 {
    font-family: var(--fonts-style-x);
    font-size: 1em;
}

p {
    font-size: 1em;
}

a {
    text-decoration: none;
}

::selection {
    color: #fff;
    background-color: #005AEE;
}

/* ------------- */
/* Content Style */
/* ------------- */

.card-container {
    position: relative;
    display: grid;
    // se tiver 3
    /* grid-template-columns: 1fr 1fr 1fr; */
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    width: 100%;
    max-width: 1200px;
    height: auto;
    padding-bottom: 5%;
    font-family: 'neuzeit-grotesk', sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 1em;
    line-height: 1.5;
    color: #303336;

    .card {
        height: 470px;
        border-radius: 10px;
        background-color: #fff;
        box-shadow: 0 40px 40px -20px #8fc7d544;
        overflow: hidden;

        .card-top {
            height: 236px;
            font-family: 'neuzeit-grotesk', sans-serif;
            font-weight: 300;
            font-style: normal;
            font-size: 1em;
            line-height: 1.5;
            color: #303336;
            background-color: #d1e8ee;
            overflow: hidden;

            a {
                display: block;  
                height: 100%;

                img {
                    width: 100%;
                    height: inherit;
                    object-fit: cover;
                    object-position: center; 
                    transition: 300ms;

                    &:hover {
                        opacity: .75;
                    }
                }

            }
        }

        .card-content {
            padding: 30px;

            .tag {
                font-weight: 700;
                letter-spacing: 2px;
            }

            .tag-travel {
                color: #33459b;
            }

            .tag-nature {
                color: #339b4b;
            }

            .tag-architecture {
                color: #9b3d33;
            }

            .title {
                color: #303336;
                font-weight: 700;
                transition: 300ms;

                &:hover {
                    color: #303336bb;
                }
            }

            .button {
                color: #303336;
                font-size: 1em;
                padding-top: 20px;
                font-weight: 700;
                transition: 300ms;

                &:hover {
                    color: #303336bb;
                }
            }

            p {
                color: #7B8591;
                margin-top: 20px;
            }
        }

    }
}

/* Display this style when screen-width is lower than 1240px */
@media only screen and (max-width: 1240px) {

    .card-container {
        grid-template-columns: 1fr 1fr;
        height: auto;
        margin: 0;
        padding: 90px;
    }

    .card {
        height: auto;
    }

}

/* Display this style when screen-width is lower than 768px */
@media only screen and (max-width: 768px) {

    .card-container {
        grid-template-columns: 1fr;

        .card-content {
            .title {
                font-size: 2em;
            }
        }
    }

}

/* Display this style when screen-width is lower than 425px */
@media only screen and (max-width: 425px) {

    .card-container {
        padding: 20px;

        .card-content {
            .title {
                font-size: 2em;
            }
        }
    }
}
</style>