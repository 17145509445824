<template>
  <div class="body-article">
        <div class="article-content">
            <h3>Nossa História</h3>
            <p>
                O Espaço Prisma da Colina foi a muitos anos atrás local de passagem dos indígenas onde por ser um local alto 
                tinha-se mais segurança, muito comum era encontrar antigamente panelas e machadinhos enterrados que foram 
                deixadas para trás. Com essa informação ficar claro o porquê este local tem uma energia tão pura e especial.
            </p>
            <img src="../assets/gallery-chale/05.jpg" alt="" class="float-left">
            <p>
                Tudo começou em 2019 quando observar apenas as montanhas não era mais suficiente e foi planejado o chalé, uma 
                idéia única para um local com uma vista única. Com o tempo a ideia de compartilhar esse local se tornou real e 
                a procura pelo chalé só aumentou,  juntando o local diferente cercado pela mata com uma vista de tirar o fôlego 
                e também sempre recebendo nossos hóspedes com muito afeto e boas energias nos tornamos superhost pelo Airbnb há 
                quase três anos consecutivos. Oferecer essa vivência diferenciada regada de amor é o nosso diferencial.
            </p>
			<h3>Energia única</h3>
			<p>
				A ideia da casa na árvore surgiu desde a época da construção do chalé mas só em 2021 foi colocada para locação. 
                Um local com energia única, em meio a mata construída nos pés de ficus surgiu um sonho de infância, todo aconchegante 
                com energia alegre como uma sensação de voltar a ser criança outra vez. Esse contato tão direto com a natureza faz 
                com que olhamos mais para si e lembrarmos o que nos traz a verdadeira felicidade. Uma experiência inesquecível para 
                agradar seu infantil interior.
			</p>
        </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

.body-article {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    gap: 2rem;
    padding: 1.5rem 0;

    .article-content {
        color: white;

        p {
            color: inherit;
            line-height: 1.8;
            text-align: justify;
            text-justify: inter-word;
        }

        p:first-letter {
			font-size: 2rem;
		}

        h3 {
            margin: 15px;
        }

        img {
            max-width: 100%;
            width: 10rem;
            margin: 0.6rem 0;
            border-radius: 0.5rem;
            box-shadow: 0 0.4rem 3rem rgba(0, 0, 0, 0.2);

            &.float-left {
                float: left;
                margin-right: 2rem;
            }
            &.float-right {
                float: right;
                margin-left: 2rem;
            }
        }
    }
}
</style>