<template>
<section>
        <div class="book">
            <h2 class="left">reserve</h2>
            <!-- <h2 class="line"></h2> -->
            <h2 class="right">agora</h2>
            <span>!</span>
        </div>
        <div class="links">
            <a href="https://www.airbnb.com.br/rooms/42903901?source_impression_id=p3_1652748308_Acd6UGX%2F85QXUt0d" target="_blank">Chalé <i class="fab fa-airbnb"></i></a>
            <a href="https://www.airbnb.com.br/rooms/50214805?source_impression_id=p3_1653785744_unKR1lmpm4SWOpti" target="_blank">Casa da Árvore <i class="fab fa-airbnb"></i></a>
            <a href="https://www.airbnb.com.br/rooms/555724023855117231?_set_bev_on_new_domain=1654035964_YzQxYWFkYmI1MzI5&source_impression_id=p3_1654035967_ESMHk9loLvyGRvk8" target="_blank">Todo o Espaço <i class="fab fa-airbnb"></i></a>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
section {
    display: flex;
    flex-direction: column;
    align-items: center;

    .book {
        display: flex;
        justify-content: center;
        margin-top: 5%;
    
        .left {
            /* margin-right: 1rem; */
            color: #000;
            letter-spacing: -3px;
        }
    
        .right {
            margin-left: 0.5rem;
            letter-spacing: -3px;
        }

        .line {
            border-right: 1px solid #000;
            height: 26px;
            align-items: center;
            margin-top: 1.9rem;
        }
    
        span {
            font-size: 2.5rem;
            margin-left: 0.5rem;
            padding-top: 7%;
            font-weight: 700;
            font-family: $font-cta;
        }
    
        h2 {
            color: #fff;
            font-size: 3.5rem;
            font-family: $font-cta;
            font-weight: 600;
            /* text-shadow: 1px 1px 3px #000; */
        }

        @media (max-width: 550px) {
		    span {
                font-size: 1.8rem;
                padding-top: 6%;
            }

            h2 {
                font-size: 2.5rem;
            }

            .line {
                margin-top: 1.2rem;
            }
	    }
    }
    
    .links {
        margin-top: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 110px;
        width: 380px;
        flex-wrap: wrap;
        @media (max-width: 550px) {
		    flex-direction: column;
            align-items: center;
            min-height: 200px;
	    }
    
        a {
            background: #000;
            color: #fff;
            border-radius: 8px;
            padding: 0.6rem 1.8rem;
            font-size: 1.2rem;
            cursor: pointer;
            transition: opacity 0.3s;
            @media (max-width: 550px) {
                font-size: 1rem;
                padding: 0.8rem 1.2rem;
	        }
    
            &:hover {
                opacity: 0.8;
                color: aqua;
            }

            /* &:nth-of-type(1)  {
                align-self: start;
            } */
        }
    }
}
</style>