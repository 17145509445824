<template>
	<main>
		<section class="container">
			<div class="top-text">
				<h2>BenjaFicus</h2>
				<hr />
			</div>
			<div class="intro">
				<div class="intro-content">
					<p>
						Reconecte-se à natureza neste lugar inesquecível. Uma experiência única para quem tem espírito 
						aventureiro ou aquele sonho de infância e quer sair da rotina. Tudo bem rústico para se conectar 
						a mata que cerca o local.
					</p>
				</div>
			</div>
			<!-- <Text /> -->
			<GalleryBenjaFicus />
			<!-- <Thought /> -->
		</section>
	</main>
</template>

<script>
// import Texto from "./components/Texto.vue"
import Text from '../components/Text.vue'
import Thought from '../components/Thought.vue'
import GalleryBenjaFicus from '../components/GalleryBenjaFicus.vue'

export default {
	name: 'BenjaFicus',
	mounted () {
		window.scrollTo(0, 0)
	},
	components: {
		Text,
		Thought,
		GalleryBenjaFicus,
	},
}
</script>

<style lang="scss" scoped>
/* @import "../styles/_variables.scss"; */

main {
  justify-content: center;
  align-items: center;
}

.container {
  margin: 5% auto;
}

.top-text {
	margin-top: 5%;
	height: 5rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: $color-text-light;
	@media (max-width: 550px) {
		margin-top: 30%;
	}

	h2 {
		font-size: 2rem;
		padding-bottom: 1.1rempx;
		text-transform: uppercase;
		@media (max-width: 550px) {
			font-size: 2rem;
		}
	}

	hr:nth-child(2) {
		max-width: 85px;
		margin-bottom: 16px;
	}

	hr:nth-child(4) {
		height: 2px;
		background-color: #fff;
		border: none;
		max-width: 615px;
		/* margin-top: 16px; */
	}
}

.intro {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    gap: 2rem;
    padding-top: 0.1rem;
    padding-bottom: 0.2rem;

    .intro-content {
        color: $color-text-light;

        p {
            color: inherit;
            line-height: 1.8;
            text-align: justify;
            text-justify: inter-word;
        }

		p:first-letter {
			font-size: 2rem;
		}
    }
}

</style>