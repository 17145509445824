<template>
<div class="splash">
    <div class="logo">
        <i class="fas fa-mountain"></i>
    </div>
    <div class="title">
        <span>Hospedagem</span>
        <h1>espaço PRISMA DA COLINA</h1>
    </div>
    <div class="subtitle">
        <h2>Experiência única em meio a natureza.</h2>
        <h3>Corupá/SC</h3>
    </div>
</div>
</template>

<script>
export default {
    name: 'Mountain',
}
</script>

<style lang="scss" scoped>
.splash {
    padding-top: 120px;
    display: flex;
	flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .logo {
        align-content: flex-start;
        margin-bottom: 10px;

        i {
            display: flex;
            justify-content: center;
            font-size: 30px;
            background: $color-mountain;
            width: 80px;
            height: 80px;
            line-height: 80px;
            color: $color-mountain-light;
            border-radius: 50%;
        }
    }

    .title {
        flex-wrap: wrap;

        span {
            margin-left: 3px;
            letter-spacing: 1em;
            color: $color-mountain-light;
            font-family: $font-mountain-title;
            text-shadow: 1px 1px 3px #000;
        }

        h1::after {
            position: absolute;
            background: $color-mountain;
            content: '';
            bottom: -24px;
            left: 50%;
            width: 110px;
            height: 4px;
            transform: translateX(-50%);
        }

        h1 {
            margin-top: -10px;
            position: relative;
            color: $color-mountain-light;
            font-family: $font-mountain-title;
            text-transform: uppercase;
            font-size: 4rem;
            text-shadow: 1px 1px 3px #000;
        }
    }

    .subtitle {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        
        h2 {
            font-family: $font-mountain-subtitle;
            color: $color-mountain-light;
            /* opacity: 0.8; */
            font-size: 2.3rem;
            letter-spacing: 0.1em;
            text-shadow: 1px 1px 1px $color-mountain;
            margin-top: 4rem;
        }

        h3 {
            font-family: $font-mountain-subtitle;
            color: $color-mountain-light;
            /* opacity: 0.8; */
            font-size: 1rem;
            letter-spacing: 0.1rem;
            font-style: italic;
            justify-content: center;
            /* text-shadow: 2px 2px 1px $color-mountain; */
            /* margin: 48px 0 0 0; */
        }
    }
}

@media (max-width: 550px) {
    .splash {
        padding-top: 100px;
        /* display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: column; */

        .logo {
            align-content: flex-start;
            margin-bottom: 1px;

            i {
                font-size: 20px;
                width: 50px;
                height: 50px;
                line-height: 50px;
            }
        }

        .title {
            margin-top: 20px;
            width: 19rem;
            span {
                /* margin-left: 2.3rem; */
                font-size: 0.7rem;
                letter-spacing: 0.4rem;
            }

            h1 {
                text-align: center;
                margin-top: -5px;
                font-size: 2rem;
            }

            h1::after {
                position: absolute;
                background: $color-mountain;
                content: '';
                bottom: -17px;
                left: 50%;
                width: 110px;
                height: 4px;
                transform: translateX(-50%);
            }
        }

        .subtitle {
            h2 {
                margin-top: 2rem;
                font-size: 0.9rem;
            }

            h3 {
                font-size: 0.7rem;
                text-align: center;
            }
        }
    }
}

</style>