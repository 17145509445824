<template>
    <h1>teste</h1>
    <blockquote class="pullquote">
      "Mirante para a linda cadeia de montanhas da Serra Catarinense de Corupá. Toda a parede frontal do Chalé 
      é feita de janelas garimpadas em demolições, permitindo que se durma sob um lindo céu estrelado, contornado 
      pela linha da Serra no horizonte. O assoalho de madeira antiga, os móveis antigos - heranças de até 100 anos 
      de idade - a biblioteca particular com centenas de livros e o enorme jardim com uma diversidade de pássaros, 
      dão charmes especiais ao lugar." &mdash; Victor Hugo
    </blockquote>
</template>

<script>
export default {
  name: 'Thought',
}
</script>

<style lang="scss" scoped>
blockquote {
    position: relative;
    margin: 40px 40px;
    padding: 40px;
    background-color: #f8f8f8;
    border-radius: 16px;

    &::before {
      content: url("https://icons.craftwork.design/static/media/QuotesFill.f65b03951f44e212816420b00909f4df.svg");
      position: absolute;
      top: -10px;
      left: -10px;
      transform: scale(2);
      opacity: 0.1;
    }

    &::after {
      content: url("https://icons.craftwork.design/static/media/QuotesFill.f65b03951f44e212816420b00909f4df.svg");
      position: absolute;
      bottom: -10px;
      right: -10px;
      transform: scale(2) rotate(180deg);
      opacity: 0.1;
    }
  }
</style>