<template>
<div class="intro-text">
    <div class="intro-lead">Corupá/SC</div>
    <div class="intro-hosting">hospedagem</div>
    <div class="intro-espace">Espaço</div>
    <div class="intro-heading">Prisma da colina.</div>
    <div class="intro-slogan">Experiência única em meio a natureza.</div>
    <!-- <a href="#" class="page-scroll btn btn-xl">Espaço Prisma</a> -->
</div>
</template>

<script>
export default {
  name: 'TopText'
}
</script>

<style lang="scss" scoped>
.intro-text {
  padding-top: 100px;
  padding-bottom: 50px;
  
  .intro-lead {
    margin-bottom: 50px;
    font-family: "Droid Serif","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 22px;
    font-style: italic;
    line-height: 22px;
  }

  .intro-hosting {
    margin-bottom: 50px;
    font-family: "Droid Serif","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 22px;
    line-height: 22px;
    text-transform: uppercase;
  }

  .intro-heading {
    margin-bottom: 25px;
    text-transform: uppercase;
    font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 50px;
    font-weight: 700;
    line-height: 50px;
  }
}
@media(min-width:768px) {
    .intro-text {
      padding-top: 15px;
      padding-bottom: 100px;

      .intro-lead {
        margin-bottom: 5px;
        font-family: "Droid Serif","Helvetica Neue",Helvetica,Arial,sans-serif;
        font-size: 40px;
        font-style: italic;
        line-height: 40px;
      }

      .intro-heading {
        margin-bottom: 50px;
        text-transform: uppercase;
        font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
        font-size: 75px;
        font-weight: 700;
        line-height: 75px;
      }
    }
}
</style>