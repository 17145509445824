import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";
import Chale from "@/views/Chale.vue";
import BenjaFicus from "@/views/BenjaFicus.vue";
import PageNotFound from "@/components/PageNotFound.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/chale",
    name: "Chale",
    component: Chale,
  },
  {
    path: "/benjaficus",
    name: "BenjaFicus",
    component: BenjaFicus,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "PageNotFound",
    component: PageNotFound,
  },
];


const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;