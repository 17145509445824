<template>
	<main>
		<section class="container">
			<Mountain />
			<Booking />
			<!-- <Stamp /> -->
			<Carousel :navigation="true" :pagination="true" :startAutoPlay="false" :timeout="10000" class="carousel" v-slot="{ currentSlide }">
				<Slide v-for="(slide, index) in carouselSlides" :key="index">
					<div v-show="currentSlide === index + 1" class="slide-info">
						<!-- <div class="text-content">
							<h2>{{slide.title}}</h2>
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum.</p>
						</div> -->
						<img :src="require(`../assets/carousel/${slide.img}.jpg`)" alt="">
					</div>
				</Slide>
			</Carousel>
			<Text />
			<Card />
		</section>
	</main>
</template>

<script>
import Carousel from '../components/Carousel.vue'
import Slide from '../components/Slide.vue'
import Card from '../components/Card.vue'
import Text from '../components/Text.vue'
import Mountain from '../components/Mountain.vue'
import TopText from '../components/TopText.vue'
import Stamp from '../components/Stamp.vue'
import Booking from '../components/Booking.vue'
// @ is an alias to /src
export default {
	name: "Home",
	components: {
		Carousel,
		Slide,
		Card,
		Text,
		Mountain,
		Stamp,
		Booking,
		TopText
	},
	mounted () {
		window.scrollTo(0, 0)
	},
	setup() {
		const carouselSlides = [{img:'prismadacolina02',title:'texto 1'},{img:'benjaficus01',title:'texto 1'},{img:'prismadacolina03',title:''},{img:'benjaficus02','title':''},{img:'prismadacolina04','title':''},{img:'together01','title':''}]
		return { carouselSlides }
	}
};
</script>

<style lang="scss" scoped>

main {
  justify-content: center;
  align-items: center;
}

.carousel {
	/* full */
	/* position: relative;
	max-height: 100vh;
	height: 80vh; */
	
	/* less width */
  	position: relative;
    max-height: 100%;
    height: 70vh;
    top: 76px;
    width: 100%;
	margin: 0 auto;
	margin-bottom: 100px;

  .slide-info {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	max-height: 100%;
	height: 100%;
  }
  
  img { 
	min-width: 100%;
	height: 100%;
	object-fit: cover;
  }
}

@media (min-width: 550px) {
  .slide-info {
	width: 50%;
  }
}

@media (max-width: 550px) {
  .carousel {
	  	/* margin-left: 30px; */
		height: 30vh;

		img {
			width: 100px;
		}
  }
}

@media (max-width: 1980px) {
  .carousel {
	  	/* margin-left: 30px; */
		height: 50vh;

		img {
			width: 100px;
		}
  }
}

@media (max-width: 2560px) {
  .carousel {
	  	/* margin-left: 30px; */
		height: 50vh;

		img {
			width: 100px;
		}
  }
}
</style>