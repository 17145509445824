<template>
<footer>
	<div class="footer-content">
    	<h3>Nossos Contatos</h3>
		<div class="socials">
			<ul>
				<li>
					<a class="link" href="https://www.instagram.com/prismadacolina/" target="_blank"><i class="fab fa-instagram"></i></a>
					<a href="https://www.instagram.com/prismadacolina/" target="_blank"><span>Instagram</span></a>
				</li>
				<li>
					<a class="link" href="https://www.airbnb.com.br/rooms/42903901?source_impression_id=p3_1652748308_Acd6UGX%2F85QXUt0d" target="_blank"><i class="fab fa-airbnb"></i></a>
					<a href="https://www.airbnb.com.br/rooms/42903901?source_impression_id=p3_1652748308_Acd6UGX%2F85QXUt0d" target="_blank"><span class="name">Chalé Prisma da Colina</span></a>
				</li>
				<li>
					<a class="link" href="https://www.airbnb.com.br/rooms/50214805?source_impression_id=p3_1653785744_unKR1lmpm4SWOpti" target="_blank"><i class="fab fa-airbnb"></i></a>
					<a href="https://www.airbnb.com.br/rooms/50214805?source_impression_id=p3_1653785744_unKR1lmpm4SWOpti" target="_blank"><span class="name">Casa da Árvore Benjaficus </span></a>
				</li>
				<li>
					<a class="link" href="https://www.airbnb.com.br/rooms/555724023855117231?_set_bev_on_new_domain=1654035964_YzQxYWFkYmI1MzI5&source_impression_id=p3_1654035967_ESMHk9loLvyGRvk8" target="_blank"><i class="fab fa-airbnb"></i></a>
					<a href="https://www.airbnb.com.br/rooms/555724023855117231?_set_bev_on_new_domain=1654035964_YzQxYWFkYmI1MzI5&source_impression_id=p3_1654035967_ESMHk9loLvyGRvk8" target="_blank"><span class="name">Todo o Espaço </span></a>
				</li>
			</ul>
		</div>
	</div>
	<div class="footer-bottom">
		<p>Copyright &copy;2019 - {{ new Date().getFullYear() }} <a href="#">espacoprismadacolina.com.br</a>  </p>
	</div>
</footer>
</template>

<script>

export default {
	name: 'Footer',
	data() {
		return { 
			socials: [
				{name:"facebook", icon:"far fa-bars", url:"#"},
				{name:"twitter", icon:"mdi-twitter-box", url:"#"},
				{name:"google", icon:"mdi-google", url:"#"},
				{name:"linkedin", icon:"mdi-linkedin-box", url:"#"},
				{name:"instagram", icon:"mdi-instagram", url:"#"}
			],
		}
	},
}
</script>

<style lang="scss" scoped>
footer {
    background: linear-gradient(45deg, rgba(purple, 0.96), rgba(red, 0.66));
    height: auto;
    width: 100%;
    padding-top: 40px;
    color: #fff;

	.footer-content {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		text-align: center;

		h3 {
			font-size: 2.1rem;
			font-weight: bold;
			text-transform: capitalize;
			line-height: 2.5rem;
		}
	}

	.socials {
			margin: 3rem 0 3rem 0;

			ul {
				display: flex;
			}

			li {
				display: flex;
				flex-direction: column;
				align-items: center;
				list-style: none;
				margin: 0 40px;

				.link {
				text-decoration: none;
				color: #fff;
				border: 1.1px solid white;
				padding: 5px;
				border-radius: 50%;
				text-align: center;
				}

				.link i {
					font-size: 1.1rem;
					width: 20px;
					transition: color .4s ease;
				}

				.link:hover i {
					color: aqua;
				}

				a {
					padding-top: 0.5rem;

					span {
						text-decoration: none;
						color: #fff;
						transition: 300ms;

						&:hover {
							color: aqua;
						}
					}
				}

			}

		}


	.footer-bottom {
		background: #000;
		width: 100%;
		padding: 20px;
		padding-bottom: 40px;
		display: flex;
    	align-items: center;
    	flex-direction: column;
		text-align: center;

		p {
			float: left;
			font-size: 14px;
			word-spacing: 2px;
			text-transform: capitalize;

			a {
				color:#44bae8;
				font-size: 16px;
				text-decoration: none;
			}
		}

		span {
			text-transform: uppercase;
			opacity: .4;
			font-weight: 200;
		}
	}
}

@media (max-width:500px) {
	.socials {
		ul {
			flex-direction: column;
			
			li {
				margin: 18px 17px;
			}
		}
	}
}

</style>

<!-- footer {
	bottom:0px;
	min-height: 10vh;
	background: linear-gradient(45deg, rgba(purple, 0.96), rgba(red, 0.66));
	padding-left: 10%;
	padding-right: 10%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	ul {
		display: flex;
		flex: 1;
		list-style: none;
		justify-content: space-between;
	}
	h1 {
		flex: 3;
	}
} -->