<template>
	<main>
		<section class="container">
			<div class="top-text">
				<!-- &nbsp; &mdash; traço -->
				<h2>Chalé Prisma da Colina </h2>
				<hr />
			</div>
			<div class="intro">
				<div class="intro-content">
					<p>
						Mirante para a linda cadeia de montanhas da Serra Catarinense de Corupá. 
						Toda a parede frontal do Chalé é feita de janelas garimpadas em demolições, 
						permitindo que se durma sob um lindo céu estrelado, contornado pela linha da 
						Serra no horizonte. O assoalho de madeira antiga, os móveis antigos - heranças 
						de até 100 anos de idade - a biblioteca particular com centenas de livros e o 
						enorme jardim com uma diversidade de pássaros, dão charmes especiais ao lugar.
					</p>
				</div>
			</div>
			<!-- <Text /> -->
			<GalleryChale />
			<!-- <Buttons /> -->
			<!-- <Thought /> -->
		</section>
	</main>
</template>

<script>
// import Texto from "./components/Texto.vue"
import Text from '../components/Text.vue'
import Thought from '../components/Thought.vue'
import GalleryChale from '../components/GalleryChale.vue'
import Buttons from '../components/Buttons.vue'

export default {
  name: 'Chale',
	mounted () {
		window.scrollTo(0, 0)
	},
	components: {
		Text,
		Thought,
		GalleryChale,
		// Buttons
	},
}
</script>

<style lang="scss" scoped>
/* @import "../styles/_variables.scss"; */

main {
  justify-content: center;
  align-items: center;
}

.container {
  margin: 5% auto;
}

.top-text {
	margin-top: 5%;
	height: 5rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: $color-text-light;
	@media (max-width: 550px) {
		margin-top: 30%;
	}

	h2 {
		font-size: 2rem;
		padding-bottom: 1.1rempx;
		text-transform: uppercase;
		@media (max-width: 550px) {
			font-size: 2rem;
		}
	}

	hr:nth-child(2) {
		max-width: 85px;
		margin-bottom: 16px;
	}

	hr:nth-child(4) {
		height: 2px;
		background-color: #fff;
		border: none;
		max-width: 615px;
		/* margin-top: 16px; */
	}
}

.intro {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    gap: 2rem;
    padding-top: 0.1rem;
    padding-bottom: 0.2rem;

    .intro-content {
        color: $color-text-light;

        p {
            color: inherit;
            line-height: 1.5;
            text-align: justify;
            text-justify: inter-word;
        }

		p:first-letter {
			font-size: 2rem;
		}
    }
}

</style>