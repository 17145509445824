<template>
  <section>
      <span>😢</span>
      <h3>Página não encontrada.</h3>
  </section>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
section {
    padding-top: 20%;
    display: flex;
    height: 60vh;
    /* height: 450px; */
    /* width: 523px; */
    justify-content: center;
}

@media (max-width: 550px) {
    section {
        padding-top: 50%
    }
}
</style>