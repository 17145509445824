<template>
<div>
    <span class="stamp">
        <a href="https://www.airbnb.com.br/rooms/42903901?source_impression_id=p3_1652487693_1E3z0bGw2eO0kUgc" target="_blank">
            <span class="">Faça agora sua reserva </span><i class="fab fa-airbnb"></i>
        </a>
    </span>
</div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.stamp {
    /* position: relative; */
    margin-top: 1.9rem;
    margin-bottom: 2.7rem;
    margin-left: 35rem;
    transform: rotate(352deg);
    color: #555;
    font-size: 1.8rem;
    font-weight: 700;
    border: 0.25rem solid #555;
    display: inline-block;
    padding: 0.25rem 1rem;
    text-transform: uppercase;
    border-radius: 1rem;
    font-family: 'Courier';
    -webkit-mask-image: url('@/assets/stamp/grunge.png');
    -webkit-mask-size: 944px 604px;
    mix-blend-mode: multiply;

    a {
        color: black;
    }
}
@media (max-width: 550px) {
    .stamp {
        margin-left: 20px;
        font-size: 1.1rem;
        margin-top: 1.9rem;
        margin-bottom: 0px;
        transform: rotate(353deg);
    }
}
</style>